import { Box } from '@mui/material';
import { IUpcSearchNotification } from '../../../../../models/responses';
import { defaultColors } from '../../../../../styles/variables';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface IProps {
  notification: IUpcSearchNotification;
}

export default function UpcSearchNotification(props: IProps) {
  const styles = UpcSearchNotificationStyles;

  return (
    <Box sx={styles.container}>
      {props.notification.isError ? (
        <Box sx={styles.error}>
          <ErrorOutlineIcon sx={{ marginRight: '6px' }} />
          {props.notification.message}
        </Box>
      ) : (
        <Box sx={styles.message}>{props.notification.message}</Box>
      )}
    </Box>
  );
}

const UpcSearchNotificationStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: '14px',
    fontSize: '14px',
  },
  error: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    lineHeight: '17px',
    color: defaultColors.red,
    marginBottom: '16px',
  },
  message: {
    width: '460px',
    lineHeight: '20px',
    fontWeight: 500,
  },
};
