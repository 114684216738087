import { IContract, IContractTerms } from '../../../../models';
import { defaultColors } from '../../../../styles/variables';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { ValidationIssue } from '../../../../classes/ValidationIssue';
import { useContractShowErrorsStore } from '../../../../store';
import { ValidationScopes } from '../../../../models/enums';
import { tss } from 'tss-react';
import { CiCircleMore } from 'react-icons/ci';
import SelectedStoresIconPopover from './SelectedStoresIconPopover';
import { useMemo } from 'react';

interface IProps {
  contract: IContract;
  contractTerms: IContractTerms;
  editContract: () => void;
  errors?: ValidationIssue[];
}

export default function ContractHeader(props: IProps) {
  const { showErrors } = useContractShowErrorsStore();

  const hasErrors = useMemo(() => {
    return showErrors && (props.errors || []).some((q) => q.scope === ValidationScopes.ContractHeader);
  }, [showErrors, props.errors]);

  const { classes, cx, css } = useStyles({ hasErrors });

  function errorIndicator() {
    return (
      <div className={classes.errorIndicator}>
        <ErrorOutlineIcon className={classes.missingInfoIcon} />
        <div>Missing field(s)</div>
      </div>
    );
  }

  const customerDisplay = `${props.contractTerms.customer?.customerId ?? ''} / ${props.contractTerms.customer?.name ?? ''}`;
  return (
    <>
      <div className={classes.root}>
        <div className={classes.fieldsContainer}>
          <div className={classes.detail}>
            <div className={classes.name}>Contract #:</div>
            <div className={classes.value} title={props.contractTerms.vendorContractNumber}>
              <span className={classes.ellipsis}>{props.contractTerms.vendorContractNumber}</span>
            </div>
          </div>
          <div className={classes.detail}>
            <div className={classes.name}>Bill to Acct:</div>
            <div className={classes.value} title={customerDisplay}>
              <span className={classes.ellipsis}>{customerDisplay}</span>
            </div>
          </div>
          <div className={classes.detail}>
            <div className={classes.name}>Category Manager:</div>
            <div className={classes.value} title={props.contractTerms.categoryManager?.name}>
              <span className={classes.ellipsis}>{props.contractTerms.categoryManager?.name}</span>
            </div>
          </div>
          <div className={classes.detail}>
            <div className={classes.name}>Supplier:</div>
            <div className={classes.value} title={props.contractTerms.supplier?.name}>
              <span className={classes.ellipsis}>{props.contractTerms.supplier?.name ?? 'None'}</span>
            </div>
          </div>
          <div className={cx(classes.detail)}>
            <div className={classes.name}>Stores:</div>
            <div className={classes.value}>
              <SelectedStoresIconPopover stores={props.contractTerms.stores} />
            </div>
          </div>
          <div className={cx(classes.detail)}>
            <div className={classes.name}>Internal Contract #</div>
            <div className={classes.value}>
              <span className={classes.ellipsis}>{!!props.contract.contractId && props.contract.contractId}</span>
            </div>
          </div>
        </div>
        <div className={cx(classes.openModal)}>
          <CiCircleMore title="View more contract details" onClick={props.editContract} className={classes.openModalIcon} />
          {hasErrors && errorIndicator()}
        </div>
      </div>
    </>
  );
}

const useStyles = tss.withParams<{ hasErrors: boolean }>().create(({ hasErrors }) => ({
  root: {
    height: '60px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: defaultColors.altGrey,
    borderBottom: `1px solid ${defaultColors.border}`,
    cursor: 'default',
  },
  fieldsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  detail: {
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    lineHeight: '19px',
    overflow: 'hidden',
    textAlign: 'left',
    fontWeight: 500,
    marginLeft: '10px',
    marginRight: '10px',
    height: '100%',
  },
  name: {
    color: defaultColors.darkGrey,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    marginRight: '10px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  value: {
    color: defaultColors.mediumGrey,
    fontWeight: 500,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '200px',
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'block',
    width: '100%',
  },
  openModal: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '19px',
    textAlign: 'left',
    fontWeight: 500,
    marginLeft: '10px',
    marginRight: '10px',
    height: '100%',
    position: 'relative',
  },
  openModalIcon: {
    color: hasErrors ? defaultColors.red : defaultColors.darkGrey,
    cursor: 'pointer',
    fontSize: '22px',
  },
  errorIndicator: {
    borderRadius: '20px',
    border: `1px solid ${defaultColors.border}`,
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: defaultColors.white,
    paddingRight: '8px',
    fontWeight: 500,
    textWrap: 'nowrap',
    position: 'absolute',
    color: defaultColors.red,
    top: '-4px',
    right: '-54px',
  },
  missingInfoIcon: {
    height: '19px',
    color: hasErrors ? defaultColors.red : defaultColors.darkGrey,
  },
}));
