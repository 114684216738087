import { Box, Checkbox, FormControl, FormHelperText, InputLabel, ListItemText, MenuItem } from '@mui/material';
import Select from '@mui/material/Select';
import { useApplicationContextState } from '../../../../../../contexts/ApplicationContext';
import { IStoreSelection, IStore, IStoreGroup } from '../../../../../../models';
import { selectInputStyles } from './NewContractModalStyles';
import { forwardRef } from 'react';

interface IProps {
  id: string;
  label: string;
  disabled: boolean;
  selections: IStoreSelection;
  options: { stores: IStore[]; storeGroups: IStoreGroup[] };
  onSelectStore: (selection: number) => void;
  onSelectStoreGroup: (selection: number) => void;
  errorMessage?: string;
  tabIndex: number;
}

const StoreSelections = forwardRef<HTMLInputElement, IProps>((props: IProps, ref) => {
  const { referenceData } = useApplicationContextState();

  const styles = selectInputStyles;

  return (
    <FormControl sx={[styles.selectWrapper, styles.focus]}>
      <InputLabel id={`${props.id}_label`} sx={styles.label} shrink={true}>
        {props.label}
      </InputLabel>
      <Select
        id={props.id}
        inputRef={ref}
        variant="outlined"
        sx={[styles.selectOverrides]}
        inputProps={{ tabIndex: props.tabIndex }}
        multiple
        renderValue={() => {
          let selection = 'All Stores';

          if (props.selections.storeIds.length === 1) {
            selection = props.options.stores.find((s) => s.id === props.selections.storeIds[0])?.displayName || '';
          } else if (props.selections.storeIds.length > 1) {
            selection = 'Multiple Stores';
          } else if (props.selections.storeGroupIds.length > 0) {
            selection = referenceData?.storeGroups.byId[props.selections.storeGroupIds[0]].displayName ?? '';
          }
          return <Box sx={styles.selection}>{selection}</Box>;
        }}
        value={[...props.selections.storeIds, ...props.selections.storeGroupIds]}
      >
        {props.options.storeGroups &&
          props.options.storeGroups.map((storeGroup) => (
            <MenuItem
              key={-1 * storeGroup.id - 1}
              value={-1 * storeGroup.id - 1}
              onClick={() => props.onSelectStoreGroup(storeGroup.id)}
              disabled={props.disabled}
            >
              <Checkbox checked={props.selections.storeGroupIds.includes(storeGroup.id)} />
              <ListItemText primary={storeGroup.displayName} />
            </MenuItem>
          ))}
        {!!props.options.storeGroups?.length && <hr />}
        {props.options.stores &&
          props.options.stores.map((store) => (
            <MenuItem key={store.id} value={store.id} onClick={() => props.onSelectStore(store.id)} disabled={props.disabled}>
              <Checkbox checked={props.selections.storeIds.includes(store.id)} />
              <ListItemText primary={store.displayName} />
            </MenuItem>
          ))}
      </Select>
      {props.errorMessage && <FormHelperText error>{props.errorMessage}</FormHelperText>}
    </FormControl>
  );
});

export default StoreSelections;
