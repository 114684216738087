import { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useApplicationContextState } from '../../../../../../contexts/ApplicationContext';
import { IContract } from '../../../../../../models';
import { RouteEnum } from '../../../../../layout/PageRouter';
import StoreSelections from './StoreSelections';
import ItemPlacementSelections from './ItemPlacementSelections';
import { HttpErrorResponse } from '../../../../../../services/contractHubApi';
import { contractTermService } from '../../../../../../services';
import { useSnackbar } from 'notistack';
import { AutoComplete, FormDialog, TextInput } from '@dierbergs-markets/react-component-library';
import { useFieldValueLookups } from './useFieldValueLookups';
import { useFormValidation } from './useFormValidation';
import { useContractFormStyles, useTextInputStyles } from './NewContractModalStyles';

interface IProps {
  initialContract: IContract;
  onContinue: (editedContract: IContract) => void;
  onCancel: () => void;
  readonly: boolean;
  hasInitialValidationErrors?: boolean;
}

const NewContractModal = (props: IProps) => {
  //Third party hooks
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  //Custom hooks
  const { referenceData } = useApplicationContextState();
  const categoryManagerOptions = (referenceData && referenceData.categoryManagers.all.filter((q) => q.isActive)) || [];
  const { searchCategoryManagers, searchCustomers, searchManufacturers, searchSuppliers } = useFieldValueLookups({ categoryManagerOptions });
  const { joiValidation } = useFormValidation();
  const { classes, css, cx } = useContractFormStyles({ readonly: props.readonly });
  const { classes: textInputClasses } = useTextInputStyles();

  //Refs
  const contractNumberRef = useRef<HTMLInputElement>(null);
  const customerRef = useRef<HTMLInputElement>(null);
  const manufacturerRef = useRef<HTMLInputElement>(null);
  const supplierRef = useRef<HTMLInputElement>(null);
  const productLineRef = useRef<HTMLInputElement>(null);
  const commentsRef = useRef<HTMLInputElement>(null);
  const categoryManagerRef = useRef<HTMLInputElement>(null);
  const storeRef = useRef<HTMLInputElement>(null);
  const processValidation = useRef<boolean>(false);

  const defaultValues: IContract = {
    ...props.initialContract,
    terms: {
      ...props.initialContract.terms,
      categoryManager: categoryManagerOptions.find((cm) => cm.id === props.initialContract.terms.categoryManager?.id),
    },
  };

  //react-hook-form config
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
    setError,
    trigger,
    clearErrors,
    watch,
  } = useForm<IContract>({ defaultValues, resolver: joiValidation, criteriaMode: 'all' });

  //Effects
  ///set a slight delay before setting focus on contract number field
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (contractNumberRef.current) {
        contractNumberRef.current.focus();

        if (!props.initialContract.contractId && props.initialContract.originalContractId) contractNumberRef.current.select();
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    (async () => props.hasInitialValidationErrors === true && !props.readonly && (await handleCustomValidation()))();
  }, [props.hasInitialValidationErrors, props.readonly]);

  useEffect(() => {
    const subscription = watch(() => {
      if (processValidation.current) handleCustomValidation();
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  const handleSelectStore = (selection: number) => {
    const result = [...getValues('terms.stores.storeIds')];

    const foundSelectionIndex = result.findIndex((x) => x === selection);
    if (foundSelectionIndex === -1) {
      if (!referenceData) return;

      const toAdd = referenceData.stores.byId[selection];
      toAdd && result.push(toAdd.id);
    } else if (foundSelectionIndex !== -1) {
      result.splice(foundSelectionIndex, 1);
    }
    setValue('terms.stores', { storeIds: [...result], storeGroupIds: [] });
  };

  const handleSelectItemPlacement = (selection: number) => {
    const result = [...getValues('terms.itemPlacementIds')];

    const foundSelectionIndex = result.findIndex((x) => x === selection);
    if (foundSelectionIndex === -1) {
      if (!referenceData) return;

      const toAdd = referenceData.itemPlacements.byId[selection];
      toAdd && result.push(toAdd.id);
    } else if (foundSelectionIndex !== -1) {
      result.splice(foundSelectionIndex, 1);
    }
    setValue('terms.itemPlacementIds', result);
  };

  const handleSelectStoreGroup = (selection: number) => {
    if (!referenceData) return;
    const toAdd = referenceData.storeGroups.all.find((sg) => sg.id === selection);
    toAdd && setValue('terms.stores', { storeIds: [], storeGroupIds: [toAdd.id] });
  };

  //Saving Form - Step 1
  async function onDialogSave() {
    // Set processValidation to true so now validation will automatically run, since using trigger disables it by default
    processValidation.current = true;

    // If no JOI errors or custom errors, submit form
    const isValid = await handleCustomValidation();
    if (isValid) await handleSubmit((data: IContract) => saveValidatedFormData(data))();
  }

  //Saving Form - Step 2
  async function handleCustomValidation() {
    // Clear form errors
    clearErrors();

    // Run JOI schema validation
    // There is sometimes a delay in updating "errors", so we need to use a flag
    let isValid = await trigger();

    // Next, run any custom validation, and add errors to joi
    if (getValues('terms.vendorContractNumber')) {
      const message = await validateVendorContractNumberAsync();
      if (message) {
        setError('terms.vendorContractNumber', { message });
        isValid = false;
      }
    }

    return isValid;
  }

  //Saving Form - Step 2.1
  async function validateVendorContractNumberAsync() {
    const response = await contractTermService.vendorContractNumberIsValid({
      contractId: props.initialContract.contractId,
      customerId: getValues('terms.customer.customerId'),
      vendorContractNumber: getValues('terms.vendorContractNumber'),
    });

    if (response instanceof HttpErrorResponse) {
      enqueueSnackbar('Unable to validate vendor contract number. Please try again.', { variant: 'error' });
      return;
    }

    if (response === false) {
      const message = props.initialContract.originalContractId
        ? 'The contract # must be a unique value.'
        : 'This contract exists. Please start a new contract with a unique Contract #.';

      return message;
    }
  }

  //Saving Form - Step 3
  function saveValidatedFormData(data: IContract) {
    props.onContinue({
      ...props.initialContract,
      terms: {
        ...props.initialContract.terms,
        categoryManager: data.terms.categoryManager,
        manufacturer: data.terms.manufacturer,
        supplier: data.terms.supplier,
        customer: data.terms.customer,
        stores: data.terms.stores,
        productLine: data.terms.productLine,
        comments: data.terms.comments,
        vendorContractNumber: data.terms.vendorContractNumber,
        itemPlacementIds: data.terms.itemPlacementIds,
      },
    });
  }

  const title = useMemo(() => {
    if (props.readonly) return 'Contract Details';

    const isNewForm = props.initialContract.contractId || location.pathname === RouteEnum.Contract ? false : true;
    return `${isNewForm ? 'New' : 'Edit'} Contract`;
  }, [props.initialContract.contractId]);

  return (
    <FormDialog
      id="NewContractModal"
      open={true}
      className={css(classes.dbgDialogOverrides)}
      onAccept={onDialogSave}
      title={title}
      acceptLabel="Continue"
      cancelLabel={'Cancel'}
      onCancel={props.onCancel}
      classes={{
        root: classes.root,
        title: classes.title,
        acceptButton: classes.acceptButton,
        cancelButton: classes.cancelButton,
      }}
      onClose={props.onCancel}
      disableEscapeKey
    >
      {referenceData && (
        <div tabIndex={-1}>
          <div className={classes.formRow}>
            <Controller
              control={control}
              name={'terms.vendorContractNumber'}
              render={({ field }) => (
                <TextInput
                  id="vendorContractNumberText"
                  label="Contract #"
                  disabled={props.readonly}
                  ref={contractNumberRef}
                  classes={{
                    root: css(classes.vendorContractNumber, textInputClasses.textInputHover),
                    input: textInputClasses.textInputInput,
                    errorText: textInputClasses.textInputError,
                  }}
                  tabIndex={1}
                  maxLength={50}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && customerRef.current) {
                      customerRef.current.focus();
                    }
                  }}
                  onChange={field.onChange}
                  value={field.value || ''}
                  errorMessage={errors.terms?.vendorContractNumber?.message}
                />
              )}
            />
          </div>
          <div className={classes.formRow}>
            <Controller
              control={control}
              name={'terms.productLine'}
              render={({ field }) => (
                <TextInput
                  id="productLineText"
                  label="Product Line"
                  disabled={props.readonly}
                  ref={productLineRef}
                  classes={{
                    root: css(classes.productLine, textInputClasses.textInputHover),
                    input: textInputClasses.textInputInput,
                  }}
                  onChange={field.onChange}
                  value={field.value}
                  errorMessage={errors.terms?.productLine?.message}
                  tabIndex={2}
                  maxLength={50}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && categoryManagerRef.current) {
                      categoryManagerRef.current.focus();
                    }
                  }}
                />
              )}
            />
          </div>
          <div className={classes.formRow}>
            <Controller
              control={control}
              name={'terms.customer'}
              render={({ field }) => (
                <>
                  <AutoComplete
                    id={'customers'}
                    label={'Bill to Account #'}
                    disabled={props.readonly}
                    inputRef={customerRef}
                    initialValue={getValues('terms.customer')}
                    onSelected={field.onChange}
                    onManualChangeFocus={(c) => {
                      if (c && manufacturerRef.current) manufacturerRef.current.focus();
                    }}
                    optionValueAsInputText
                    queryOptionsAsync={searchCustomers}
                    valueExtractor={(option) => option.customerId}
                    textExtractor={(option) => option.name}
                    errormessage={errors.terms?.customer?.message}
                    className={cx(classes.customer, { [classes.autoCompleteDisabled]: props.readonly })}
                    textInputProps={{
                      tabIndex: 3,
                    }}
                  />
                  <TextInput
                    id="CustomerNameText"
                    disabled={true}
                    label="Bill to Acct Name"
                    classes={{
                      root: css(classes.customerName, textInputClasses.textInputHover),
                      input: textInputClasses.textInputInput,
                    }}
                    onChange={() => {
                      /*unused*/
                    }}
                    value={getValues('terms.customer.name')}
                  />
                </>
              )}
            />
          </div>
          <div className={classes.formRow}>
            <div className={classes.formTwoColumnCell}>
              <Controller
                control={control}
                name={'terms.manufacturer'}
                render={({ field }) => (
                  <AutoComplete
                    id={'manufacturers'}
                    label={'Manufacturer'}
                    disabled={props.readonly}
                    inputRef={manufacturerRef}
                    initialValue={getValues('terms.manufacturer')}
                    onSelected={field.onChange}
                    onManualChangeFocus={(m) => {
                      if (m && supplierRef.current) supplierRef.current.focus();
                    }}
                    characterThreshold={2}
                    queryOptionsAsync={searchManufacturers}
                    valueExtractor={(option) => option.manufacturerId}
                    textExtractor={(option) => option.name}
                    errormessage={errors.terms?.manufacturer?.message}
                    className={cx(classes.manufacturer, { [classes.autoCompleteDisabled]: props.readonly })}
                    textInputProps={{
                      tabIndex: 4,
                    }}
                  />
                )}
              />
            </div>
            <div className={classes.formTwoColumnCell}>
              <Controller
                control={control}
                name={'terms.supplier'}
                render={({ field }) => (
                  <AutoComplete
                    id={'suppliers'}
                    label={'Supplier'}
                    disabled={props.readonly}
                    inputRef={supplierRef}
                    initialValue={getValues('terms.supplier')}
                    onSelected={field.onChange}
                    onManualChangeFocus={(m) => {
                      if (m && categoryManagerRef.current) categoryManagerRef.current.focus();
                    }}
                    characterThreshold={1}
                    queryOptionsAsync={searchSuppliers}
                    valueExtractor={(option) => option.id}
                    textExtractor={(option) => option.name}
                    errormessage={errors.terms?.supplier?.message}
                    className={cx(classes.supplier, { [classes.autoCompleteDisabled]: props.readonly })}
                    textInputProps={{
                      tabIndex: 5,
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className={classes.formRow}>
            <div className={classes.formTwoColumnCell}>
              <Controller
                control={control}
                name={'terms.categoryManager'}
                render={({ field }) => (
                  <AutoComplete
                    id="categoryManagerSelect"
                    label="Category Manager"
                    disabled={props.readonly}
                    inputRef={categoryManagerRef}
                    queryOptions={searchCategoryManagers}
                    initialValue={getValues('terms.categoryManager')}
                    onSelected={field.onChange}
                    onManualChangeFocus={(cm) => {
                      if (cm && storeRef.current) {
                        storeRef.current.focus();
                      }
                    }}
                    valueExtractor={(option) => option.id}
                    textExtractor={(option) => option.name}
                    errormessage={errors.terms?.categoryManager?.message}
                    className={cx(classes.categoryManager, { [classes.autoCompleteDisabled]: props.readonly })}
                    optionRender={(option) => <div>{option.name}</div>}
                    characterThreshold={1}
                    textInputProps={{
                      tabIndex: 6,
                    }}
                  />
                )}
              />
            </div>
            <div className={css(classes.formTwoColumnCell, { paddingLeft: '5px' })}>
              <Controller
                control={control}
                name={'terms.stores'}
                render={() => {
                  const storeIds = getValues('terms.stores.storeIds');
                  const storeGroupIds = getValues('terms.stores.storeGroupIds');
                  let storeGroupIdsOverride: number[] | null = null;
                  // If there is no group or store selection. Default to 'All Stores' Group.
                  if (storeGroupIds.length === 0 && storeIds.length === 0) {
                    const allStoresGroup = referenceData && referenceData?.storeGroups.all.find((sg) => sg.displayName === 'All Stores');
                    // If the 'All Stores' group is found, set its ID as the default value
                    if (allStoresGroup) {
                      handleSelectStoreGroup(allStoresGroup.id);
                      storeGroupIdsOverride = [allStoresGroup.id];
                    }
                  }
                  return (
                    <StoreSelections
                      id="selectedStoreIdsSelect"
                      label={getValues('terms.stores.storeGroupIds').length > 0 ? 'Store group included' : 'Stores included'}
                      disabled={props.readonly}
                      ref={storeRef}
                      onSelectStore={handleSelectStore}
                      onSelectStoreGroup={handleSelectStoreGroup}
                      tabIndex={7}
                      options={{
                        stores: referenceData.stores.all,
                        storeGroups: referenceData.storeGroups.all.sort((a, b) => a.storeGroupName.localeCompare(b.storeGroupName)),
                      }}
                      selections={{ storeIds, storeGroupIds: storeGroupIdsOverride ?? storeGroupIds }}
                      errorMessage={errors.terms?.stores?.message}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className={classes.formRow}>
            <div className={css(classes.formTwoColumnCell, { paddingRight: '5px' })}>
              <Controller
                control={control}
                name={'terms.itemPlacementIds'}
                render={() => {
                  const itemPlacements = getValues('terms.itemPlacementIds');
                  return (
                    <ItemPlacementSelections
                      id="selectedItemPlacementIdsSelect"
                      label={'Select placement location(s)'}
                      disabled={props.readonly}
                      ref={storeRef}
                      onSelect={handleSelectItemPlacement}
                      tabIndex={8}
                      options={referenceData.itemPlacements.all}
                      selections={itemPlacements}
                      errorMessage={errors.terms?.itemPlacementIds?.message}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className={classes.formRow}>
            <Controller
              control={control}
              name={'terms.comments'}
              render={({ field }) => (
                <TextInput
                  id="comments"
                  label="Comments"
                  disabled={props.readonly}
                  ref={commentsRef}
                  classes={{
                    root: css(classes.comments, textInputClasses.textInputHover),
                    input: textInputClasses.textInputInput,
                    errorText: textInputClasses.textInputError,
                  }}
                  tabIndex={9}
                  maxLength={500}
                  onChange={field.onChange}
                  value={field.value || ''}
                  errorMessage={errors.terms?.comments?.message}
                />
              )}
            />
          </div>
        </div>
      )}
    </FormDialog>
  );
};

export default NewContractModal;
