import { Box, Checkbox, FormControl, FormHelperText, InputLabel, ListItemText, MenuItem } from '@mui/material';
import Select from '@mui/material/Select';
import { IItemPlacement } from '../../../../../../models';
import { selectInputStyles } from './NewContractModalStyles';
import { forwardRef } from 'react';

interface IProps {
  id: string;
  label: string;
  disabled: boolean;
  selections: number[];
  options: IItemPlacement[];
  onSelect: (selection: number) => void;
  errorMessage?: string;
  tabIndex: number;
}

const ItemPlacementSelections = forwardRef<HTMLInputElement, IProps>((props: IProps, ref) => {
  const styles = selectInputStyles;

  return (
    <FormControl sx={[styles.selectWrapper, styles.focus]}>
      <InputLabel id={`${props.id}_label`} sx={styles.label} shrink={true}>
        {props.label}
      </InputLabel>
      <Select
        id={props.id}
        inputRef={ref}
        variant="outlined"
        sx={[styles.selectOverrides]}
        inputProps={{ tabIndex: props.tabIndex }}
        multiple
        renderValue={(value) => {
          let selection = '';
          if (value.length === 1) {
            selection = props.options.find((s) => s.id === value[0])?.name || '';
          } else if (value.length > 1) {
            selection = 'Multiple Placements';
          }
          return <Box sx={styles.selection}>{selection}</Box>;
        }}
        value={props.selections}
      >
        {props.options &&
          props.options.map((option) => (
            <MenuItem key={option.id} value={option.id} onClick={() => props.onSelect(option.id)} disabled={props.disabled}>
              <Checkbox checked={props.selections.includes(option.id)} />
              <ListItemText primary={option.name} />
            </MenuItem>
          ))}
      </Select>
      {props.errorMessage && <FormHelperText error>{props.errorMessage}</FormHelperText>}
    </FormControl>
  );
});

export default ItemPlacementSelections;
