import { Theme, alpha } from '@mui/material';
import { defaultBorders, defaultColors } from '../variables';
import { inputLabelClasses, inputClasses, formHelperTextClasses } from '@mui/material';

export const textfieldStyles = {
  textField: {
    border: `1px solid ${defaultColors.border}`,
    borderRadius: '6px',
    boxShadow: defaultBorders.boxShadow,
    height: '60px',
    backgroundColor: defaultColors.white,
    [`& .${formHelperTextClasses.root}`]: {
      position: 'relative',
      height: '0px',
      top: '6px',
    },
    '&:hover': {
      border: `1px solid ${defaultColors.darkBlueGrey}`,
      boxShadow: `0 1px 2px 0 ${defaultColors.transparentMediumGrey}`,
    },
  },
  textFieldFocus: {
    boxShadow: `0 0 0 1px ${defaultColors.blue}`,
    borderColor: defaultColors.blue,
    borderRadius: '6px',
    zIndex: 1,
    '&:hover': {
      borderColor: defaultColors.blue,
      boxShadow: `0 0 0 1px ${defaultColors.blue}`,
      borderRadius: '6px',
      zIndex: 1,
    },
    '> select': {
      border: 0,
    },
  },
  textFieldError: {
    borderColor: defaultColors.red,
    boxShadow: `0 0 0 1px ${defaultColors.red}`,
    borderRadius: '6px',
    zIndex: 1,
    '&:hover': {
      borderColor: defaultColors.red,
      boxShadow: `0 0 0 1px ${defaultColors.red}`,
      borderRadius: '6px',
      zIndex: 1,
    },
  },
  textFieldWarning: {
    borderColor: defaultColors.yellow,
    boxShadow: `0 0 0 1px ${defaultColors.yellow}`,
    borderRadius: '6px',
    zIndex: 1,
    '&:hover': {
      borderColor: defaultColors.yellow,
      boxShadow: `0 0 0 1px ${defaultColors.yellow}`,
      borderRadius: '6px',
      zIndex: 1,
    },
  },
  inputLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginLeft: '10px',
    transform: 'unset',
    letterSpacing: '0px',
    [`&.${inputLabelClasses.shrink}`]: {
      paddingTop: '8px',
      fontSize: '13px',
      lineHeight: '15px',
      letterSpacing: 0,
      '&.Mui-error': {
        color: defaultColors.mediumGrey,
      },
    },
  },
  input: {
    marginTop: '0px',
    paddingTop: '4px',
    marginLeft: '10px',
    overflow: 'hidden',
    fontSize: '15px',
    fontWeight: 500,
    transition: (theme: Theme) => theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {},
    [`& .${inputClasses.input}`]: {
      letterSpacing: '0px',
      padding: '0px',
      marginTop: '10px',
      color: defaultColors.darkGrey,
    },
  },
  textInputHover: {
    '&:hover': {
      outline: `${defaultColors.darkBlueGrey} !important`,
      boxShadow: `${alpha(defaultColors.mediumGrey, 0.1)}  !important`,
    },
  },
  textInputInput: {
    overflow: 'hidden',
    fontSize: '15px',
    fontWeight: 500,
    '&:disabled': {
      backgroundColor: 'transparent',
    },
  },
  textInputError: {
    fontSize: '11px !important',
    fontFamily: 'Inter, Poppins, sans-serif !important',
    fontWeight: '400 !important',
    lineHeight: '1.66 !important',
    marginLeft: '-10px',
  },
  textarea: {
    marginTop: '0px',
    paddingTop: '4px',
    marginLeft: '10px',
    overflow: 'hidden',
    fontSize: '15px',
    fontWeight: 500,
    transition: (theme: Theme) => theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {},
  },
  gridLeft: {
    borderRadius: '6px 0px 0px 6px !important',
    borderRight: 'unset !important',
    boxShadow: defaultBorders.boxShadow,
  },
  gridRight: {
    borderRadius: '0px 6px 6px 0px !important',
  },
};
