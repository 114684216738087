import {
  inputClasses,
  dialogClasses,
  dialogTitleClasses,
  dialogContentClasses,
  dialogActionsClasses,
  outlinedInputClasses,
  selectClasses,
} from '@mui/material';
import { defaultColors, defaultBorders } from '../../../../../../styles/variables';
import { textfieldStyles } from '../../../../../../styles/shared/TextFieldStyles';
import { tss } from 'tss-react';

export const useContractFormStyles = tss.withParams<{ readonly: boolean }>().create(({ readonly }) => ({
  root: {
    width: '620px',
  },
  title: {
    padding: '0px !important',
  },
  formRow: {
    marginBottom: '24px',
    width: '100%',
    minHeight: '60px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  formTwoColumnCell: {
    width: '50%',
  },
  vendorContractNumber: {
    width: '100%',
  },
  comments: {
    width: '100%',
  },
  autoCompleteDisabled: {
    [`& .MuiFormControl-root.MuiFormControl-fullWidth`]: {
      backgroundColor: defaultColors.rowHover,
    },
  },
  customer: {
    width: '50%',
    [`& .MuiFormControl-root.MuiFormControl-fullWidth`]: {
      height: 60,
      borderTopRightRadius: '0px !important',
      borderBottomRightRadius: '0px !important',
    },
  },
  customerName: {
    width: '50%',
    borderLeft: 'none !important',
    borderTopLeftRadius: '0px !important',
    borderBottomLeftRadius: '0px !important',
    backgroundColor: defaultColors.lightGrey,
    paddingRight: '10px',
    '&:hover': {
      border: `1px solid ${defaultColors.border}`,
      boxShadow: defaultBorders.boxShadow,
    },
    [`& .${inputClasses.input}.Mui-disabled`]: {
      textFillColor: defaultColors.darkGrey,
    },
  },
  manufacturer: {
    paddingRight: '5px',
    [`& .MuiFormControl-root.MuiFormControl-fullWidth`]: {
      height: 60,
    },
  },
  supplier: {
    paddingLeft: '5px',
    [`& .MuiFormControl-root.MuiFormControl-fullWidth`]: {
      height: 60,
    },
  },

  productLine: {
    width: '100%',
  },
  categoryManager: {
    paddingRight: '5px',
    [`& .MuiFormControl-root.MuiFormControl-fullWidth`]: {
      height: 60,
    },
  },
  acceptButton: {
    display: readonly ? 'none' : '',
    backgroundColor: `${defaultColors.green} !important`,
    width: '200px',
  },
  cancelButton: {
    display: readonly ? 'none' : '',
    backgroundColor: `${defaultColors.white} !important`,
  },
  dbgDialogOverrides: {
    [`& .${dialogClasses.paper}`]: {
      minWidth: '620px',
      paddingX: '60px',
    },
    [`& .${dialogTitleClasses.root}`]: {
      borderBottom: 'unset',
      marginTop: '45px',
      marginBottom: '25px',
      padding: '0px',
      fontSize: '25px',
    },
    [`& .${dialogContentClasses.root}`]: {
      padding: 'unset',
      overflow: 'visible', // required for input box focus
    },
    [`& .${dialogActionsClasses.root}`]: {
      borderTop: 'unset',
      padding: 'unset',
      marginBottom: '64px',
    },
  },
}));

export const useTextInputStyles = tss.create(() => {
  const { textInputError, textInputInput, textInputHover } = textfieldStyles;

  return {
    textInputHover,
    textInputInput,
    textInputError: {
      ...textInputError,
      textAlign: 'left',
    },
  };
});

export const selectInputStyles = {
  focus: {
    [`& .${outlinedInputClasses.root}`]: {
      '&.Mui-focused': {
        boxShadow: `0 0 0 2px ${defaultColors.blue} !important`,
        '& > fieldset': {
          border: 'none',
        },
      },
    },
  },
  selectOverrides: {
    width: '242px',
    height: '60px',
    borderRadius: '6px',
    boxShadow: defaultBorders.boxShadow,
    [`& .${selectClasses.select}`]: {
      padding: 'unset',
      textAlign: 'left',
      marginLeft: '11px',
      marginTop: '22px',
    },
    [`& .${selectClasses.icon}`]: {
      marginTop: '10px',
      color: defaultColors.blue,
    },
    '& fieldset': {
      border: 0,
      boxShadow: 0,
      borderWidth: '0px !important',
      '&.Mui-focused': {
        // borderWidth: '0px !important',
      },
      '& :hover': {
        border: 0,
        boxShadow: 0,
      },
    },
  },
  label: {
    ...textfieldStyles.inputLabel,
  },
  selection: {
    width: '210px',
    fontSize: '15px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: defaultColors.blue,
  },
  selectWrapper: {
    height: '60px',
    width: '100%',
    borderRadius: '6px',
    boxShadow: defaultBorders.boxShadow,
    border: `1px solid ${defaultColors.blueGrey}`,
    '&:hover': {
      border: `1px solid ${defaultColors.darkBlueGrey}`,
      boxShadow: `0 1px 2px 0 ${defaultColors.transparentMediumGrey}`,
    },
    [`& .${outlinedInputClasses.root}`]: {
      width: '100%',
    },
  },
};
