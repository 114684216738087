import { IPagedResult } from '@dierbergs-markets/react-component-library';
import { IExternalAccountCustomer, IExternalAccountDetails, IExternalAccountName } from '../../models';
import {
  fetchGetJson,
  fetchPostJson,
  SuccessOrErrorResponse,
  HttpErrorResponse,
  fetchPutSuccessOrError,
  fetchPostSuccessOrError,
  fetchDeleteSuccessOrError,
} from '../contractHubApi';
import { IBaseSearchParams } from '../../models/requests';

export const accountService = {
  searchAccounts,
  getAccount,
  getAccountCustomers,
  createAccount,
  createAccountCustomer,
  updateAccount,
  deleteAccountCustomer,
};

function getAccountCustomers(externalAccountId: number): Promise<IExternalAccountCustomer[] | HttpErrorResponse> {
  return fetchGetJson<IExternalAccountCustomer[]>({ endpoint: `account/${externalAccountId}/customer` });
}

function getAccount(externalAccountId: number): Promise<IExternalAccountName | HttpErrorResponse> {
  return fetchGetJson<IExternalAccountName>({ endpoint: `account/${externalAccountId}` });
}

export interface IExternalAccountSearchRequest extends IBaseSearchParams {
  query?: string;
  externalAccountId?: number;
  customerIdFilter?: string;
}

function searchAccounts(request: IExternalAccountSearchRequest): Promise<IPagedResult<IExternalAccountDetails> | HttpErrorResponse> {
  return fetchPostJson<IPagedResult<IExternalAccountDetails>>({ endpoint: `account/search`, body: request });
}

function createAccount(name: string): Promise<IExternalAccountName | HttpErrorResponse> {
  return fetchPostJson<IExternalAccountName>({ endpoint: `account`, body: { name } });
}

async function updateAccount(externalAccountId: number, name: string): Promise<SuccessOrErrorResponse> {
  return await fetchPutSuccessOrError({ endpoint: `account/${externalAccountId}`, body: { name } });
}

async function createAccountCustomer(externalAccountId: number, customerId: string): Promise<SuccessOrErrorResponse> {
  return await fetchPostSuccessOrError({ endpoint: `account/${externalAccountId}/customer`, body: { customerId } });
}

async function deleteAccountCustomer(externalAccountId: number, customerId: string): Promise<SuccessOrErrorResponse> {
  return await fetchDeleteSuccessOrError({ endpoint: `account/${externalAccountId}/customer/${customerId}` });
}
