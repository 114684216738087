import { DateRangePickerInput, IconButton, Select, SelectOption } from '@dierbergs-markets/react-component-library';
import { DateRange } from '@mui/x-date-pickers-pro';
import { tss } from 'tss-react';
import { FiArrowRight } from 'react-icons/fi';
import dayjs, { Dayjs } from 'dayjs';
import { defaultColors } from '../../../../styles/variables';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ContractsGridSessionState, useContractsGridSessionStore } from '../../../../store';
import AllContractsGrid from './grids/AllContractsGrid';
import OrderSurveyGrid from './grids/OrderSurveyGrid';
import { useSnackbar } from 'notistack';
import { useApplicationContextState } from '../../../../contexts/ApplicationContext';
import { SearchGridTypes, UserType } from '../../../../models/enums';
import AdPlannerExportGrid from './grids/AdPlannerExportGrid';

export default function ContractSearch() {
  //STATE
  const [dateRangeError, setDateRangeError] = useState<string | null>(null);
  const { timeFrameState, setTimeFrameState, selectedSearchType, setSelectedSearchType }: ContractsGridSessionState = useContractsGridSessionStore();
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null]);
  const { enqueueSnackbar } = useSnackbar();
  const [dateRangePickerDisabled, setDateRangePickerDisabled] = useState<boolean>(false);

  //HOOKS
  const { user, referenceData } = useApplicationContextState();
  const { classes } = useStyles();

  //MEMOS
  const pricingEnabled = useMemo(() => {
    return (user?.userType === UserType.Internal && referenceData?.pricingEnabled) ?? false;
  }, [user, referenceData]);

  const menuOptions: SelectOption[] = useMemo(() => {
    const options = [{ text: 'All Contracts', value: SearchGridTypes.AllContracts }];
    if (pricingEnabled) {
      options.push({ text: 'Order Survey', value: SearchGridTypes.OrderSurvey });
      options.push({ text: 'Ad Planner Export', value: SearchGridTypes.AdPlannerExport });
    }
    return options;
  }, [pricingEnabled]);

  //USE EFFECTS
  //enforce valid date, first time loading.
  useEffect(() => {
    const startDate = timeFrameState.startDate ?? dayjs().add(-2, 'months').toDate();

    setTimeFrameState({
      startDate: startDate,
      endDate: timeFrameState.startDate ? (timeFrameState.endDate ?? null) : null,
    });
    setDateRange([dayjs(startDate), timeFrameState.endDate ? dayjs(timeFrameState.endDate) : null]);
  }, []);

  const validateDateRange = useCallback((range: DateRange<Dayjs>) => {
    const [startDate, endDate] = range;

    if (!startDate) {
      const message = 'Start date is required';
      setDateRangeError(message);
      enqueueSnackbar(message, { variant: 'error' });
      return false;
    }

    if (startDate && endDate && startDate.isValid() && endDate.isValid() && endDate.isBefore(startDate, 'day')) {
      const message = 'Start date must be no later than end date';
      setDateRangeError(message);
      enqueueSnackbar(message, { variant: 'error' });
      return false;
    }

    if (startDate.isValid() && (!endDate || endDate.isValid())) {
      setDateRangeError(null);
      return true;
    }
    setDateRangeError('Invalid input');
    return false;
  }, []);

  //FUNCTIONS
  function renderGrid(searchType: string) {
    switch (searchType) {
      case SearchGridTypes.AllContracts:
        return <AllContractsGrid timeFrame={timeFrameState} setDateRangePickerDisabled={setDateRangePickerDisabled} />;
      case SearchGridTypes.OrderSurvey:
        return <OrderSurveyGrid timeFrame={timeFrameState} />;
      case SearchGridTypes.AdPlannerExport:
        return <AdPlannerExportGrid timeFrame={timeFrameState} />;
      default:
        return <></>;
    }
  }

  function handleSearchSelectionChange(value: string) {
    setSelectedSearchType(value);
    setDateRangePickerDisabled(false);
  }

  return (
    <div className={classes.root}>
      <div className={classes.toolBar}>
        <div className={classes.dateRangePicker}>
          <DateRangePickerInput
            id={'ContractDateRange'}
            slotProps={{ textField: { className: classes.dateRangePickerTextField } }}
            label="Select date range"
            format={'MM/DD/YY'}
            value={dateRange}
            disabled={dateRangePickerDisabled}
            onChange={setDateRange}
            errorMessage={dateRangeError ?? ''}
          />
          <div className={classes.dateRangePickerLeftArrow}>
            <IconButton
              id={'DateRangeButton'}
              onClick={() => {
                if (validateDateRange(dateRange)) {
                  setTimeFrameState({ startDate: dateRange[0]?.toDate() ?? null, endDate: dateRange[1]?.toDate() ?? null });
                }
              }}
              icon={<FiArrowRight />}
            />
          </div>
        </div>
        <Select
          id={'SelectSearch'}
          label="Select a predefined search"
          value={selectedSearchType}
          onChange={handleSearchSelectionChange}
          classes={{
            root: classes.searchTypeMenuRoot,
            focus: classes.searchTypeMenuFocus,
          }}
          items={menuOptions}
          disabled={menuOptions.length <= 1}
        />
      </div>
      <div className={classes.searchGrid}>{renderGrid(selectedSearchType)}</div>
    </div>
  );
}

const useStyles = tss.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    zIndex: 10,
    width: '100%',
  },
  toolBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: '74px',
    paddingBottom: '14px',
  },
  dateRangePicker: {
    display: 'flex',
  },
  dateRangePickerTextField: {
    height: '60px',
    borderTopRightRadius: '0px !important',
    borderBottomRightRadius: '0px !important',
  },
  dateRangePickerLeftArrow: {
    padding: '10px 0px 10px 0px',
    border: `1px solid ${defaultColors.grey}`,
    borderLeft: 'unset',
    borderRadius: '0px 6px 6px 0px',
    height: '60px',
  },
  searchTypeMenuRoot: {
    height: '60px',
    marginLeft: '20px',
  },
  searchTypeMenuFocus: {
    outline: 'unset !important',
  },
  searchGrid: {
    width: '100%',
  },
});
