import { defaultColors, defaultBorders } from '../../../../../../styles/variables';
import {
  inputClasses,
  dialogClasses,
  dialogTitleClasses,
  dialogContentClasses,
  dialogActionsClasses,
  alpha,
  outlinedInputClasses,
} from '@mui/material';

const styles = {
  dbgDialogOverrides: {
    [`& .${dialogClasses.paper}`]: {
      minWidth: '620px',
      paddingX: '10px',
    },
    [`& .${dialogTitleClasses.root}`]: {
      borderBottom: 'unset',
      marginTop: '45px',
      marginBottom: '25px',
      padding: '0px',
      fontSize: '25px',
    },
    [`& .${dialogContentClasses.root}`]: {
      padding: 'unset',
      overflow: 'visible', // required for input box focus
    },
    [`& .${dialogActionsClasses.root}`]: {
      borderTop: 'unset',
      padding: 'unset',
      marginBottom: '64px',
    },
  },
  form: {
    display: 'flex',
    justifyContent: 'center',
    toggle: {
      marginRight: '5px',
      borderRadius: '12px',
      height: '44px',
      fontSize: '15px',
      lineHeight: '19px',
      '& .Mui-disabled': {
        backgroundColor: `${defaultColors.blue} !important`,
        color: `${defaultColors.white} !important`,
        cursor: 'default',
      },
      '& :hover': {
        backgroundColor: `${alpha(defaultColors.grey, 0.4)}`,
      },
      upc: {
        width: '68px',
        textTransform: 'unset',
      },
      orderCode: {
        width: '135px',
        textTransform: 'unset',
      },
      [`& .${inputClasses.input}.Mui-disabled`]: {
        textFillColor: defaultColors.darkGrey,
      },
    },
    input: {
      [`&.${outlinedInputClasses.root}`]: {
        backgroundColor: defaultColors.white,
        width: '330px',
        marginRight: '10px',
        height: '44px',
        borderRadius: '12px',
        boxShadow: defaultBorders.boxShadow,
        borderColor: defaultColors.border,
        '& fieldset': {
          '&.Mui-focused': {
            borderWidth: '0px !important',
          },
          borderWidth: '1px !important',
          borderColor: `${defaultColors.border} !important`,
        },
        [`& .${outlinedInputClasses.input}`]: {
          textAlign: 'center',
          fontWeight: 500,
          fontSize: '20px',
          '&::placeholder': {
            color: defaultColors.grey,
          },
        },
      },
      error: {
        [`&.${outlinedInputClasses.root}`]: {
          borderColor: defaultColors.red,
          boxShadow: `0 0 0px 2px ${defaultColors.red}`,
        },
      },
      success: {
        [`&.${outlinedInputClasses.root}`]: {
          borderColor: defaultColors.green,
          boxShadow: `0 0 0px 2px ${defaultColors.green}`,
        },
      },
    },
    button: {
      color: defaultColors.mediumGrey,
      borderRadius: '12px',
      fontSize: '15px',
      lineHeight: '19px',
      border: `1px solid ${defaultColors.border}`,
      height: '44px',
      width: '44px',
      searchIcon: {
        top: '-2px',
        width: '18px',
        height: '18px',
        color: defaultColors.grey,
      },
      searchIconLoading: {
        height: '18px !important',
        width: '18px !important',
        color: defaultColors.mediumGrey,
      },
    },
    brand: {
      width: '490px',
      margin: '0 auto',
    },
    description: {
      width: '490px',
      margin: '0 auto',
    },
  },
  success: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: '14px',
    fontSize: '14px',
    type: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      lineHeight: '17px',
      color: defaultColors.green,
      marginBottom: '16px',
    },
    message: {
      width: '460px',
      lineHeight: '20px',
      fontWeight: 500,
    },
  },
  boxFlex: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
  },
  main: {
    padding: '0px 60px 0px 60px',
  },
  instruction: {
    fontSize: '14px',
    textAlign: 'center',
  },
  modalBorder: {
    borderTop: `1px solid ${defaultColors.border}`,
    borderBottom: `1px solid ${defaultColors.border}`,
  },
};

export const AdvSearchModalStyles = { styles };
