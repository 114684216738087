import { IAdPlannerExportSearchRequest, IAllContractsSearchRequest, IOrderSurveySearchRequest } from '../../models/requests';
import { IAllContractsSearchResponse } from '../../models/responses';
import { fetchPostJson, HttpErrorResponse } from '../contractHubApi';
import { IPagedResult } from '@dierbergs-markets/react-component-library';

export const contractSearchService = {
  searchAllContracts,
  orderSurveySearch,
  adPlannerExportSearch,
};

function searchAllContracts(
  request: IAllContractsSearchRequest,
  signal: AbortSignal
): Promise<IPagedResult<IAllContractsSearchResponse> | HttpErrorResponse> {
  return fetchPostJson<IPagedResult<IAllContractsSearchResponse>>({ endpoint: 'contract/search', body: request, signal });
}

function orderSurveySearch(
  request: IOrderSurveySearchRequest,
  signal: AbortSignal
): Promise<IPagedResult<IAllContractsSearchResponse> | HttpErrorResponse> {
  return fetchPostJson<IPagedResult<IAllContractsSearchResponse>>({ endpoint: 'contract/search/order-survey', body: request, signal });
}

function adPlannerExportSearch(
  request: IAdPlannerExportSearchRequest,
  signal: AbortSignal
): Promise<IPagedResult<IAllContractsSearchResponse> | HttpErrorResponse> {
  return fetchPostJson<IPagedResult<IAllContractsSearchResponse>>({ endpoint: 'contract/search/ad-planner-export', body: request, signal });
}
