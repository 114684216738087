import { HttpErrorResponse } from '@dierbergs-markets/react-component-library';
import { ArrayUtils } from '../../../../../../utilities/ArrayUtility';
import { useSnackbar } from 'notistack';
import { ICustomer, IManufacturer, ISupplier, ICategoryManager } from '../../../../../../models';
import { customerService, manufacturerService, supplierService } from '../../../../../../services';

interface IProps {
  categoryManagerOptions: ICategoryManager[];
}
export const useFieldValueLookups = (props: IProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const { categoryManagerOptions } = props;

  /**
   * Calls the provided search.
   * On success returning the results.
   * On error displays a snackbar error and returns an empty array.
   */
  async function performSearch<TResponse>(
    name: string,
    search: (query: string) => Promise<TResponse[] | HttpErrorResponse>,
    query: string
  ): Promise<TResponse[]> {
    const response = await search(query);
    if (response instanceof HttpErrorResponse) {
      enqueueSnackbar(name + ' search error.');
      return [];
    }
    return response;
  }

  async function searchCustomers(query: string): Promise<HttpErrorResponse | ICustomer[]> {
    return (await performSearch('Customer', customerService.searchCustomerId, query)).filter((c) => c.isActive);
  }

  async function searchManufacturers(query: string): Promise<IManufacturer[]> {
    return (await performSearch('Manufacturer', manufacturerService.searchManufacturer, query)).filter((m) => m.isActive);
  }

  async function searchSuppliers(query: string): Promise<ISupplier[]> {
    return await performSearch('Supplier', supplierService.search, query);
  }

  function searchCategoryManagers(query: string): ICategoryManager[] {
    let result: ICategoryManager[] = [];
    if (!categoryManagerOptions) return result;
    result = query.length > 0 ? categoryManagerOptions.filter((q) => q.name.toLowerCase().includes(query.toLowerCase())) : categoryManagerOptions;

    return ArrayUtils.orderBy(result, (x) => x.name);
  }

  return {
    searchCustomers,
    searchCategoryManagers,
    searchManufacturers,
    searchSuppliers,
  };
};
