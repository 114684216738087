import { ValidationIssue } from '../classes/ValidationIssue';
import { IReferenceDataState } from '../contexts/ApplicationContext';
import {
  ICategoryManager,
  IContract,
  IContractItem,
  IContractTermForItem,
  IContractTermForLumpSum,
  IContractTerms,
  IDepartment,
  ITermType,
} from '../models';
import { NumberToCurrencyString } from '../utilities/NumberUtility';
import { HttpErrorResponse } from './contractHubApi';
import { customerService } from './customerService';
import { manufacturerService } from './manufacturerService';
import { contractTermService } from './contract/contractTermService';
import { supplierService } from './supplierService';
import { grossMarginCalculate, unitCostCalculate } from '@dierbergs-markets/react-feature-library';
import { getPricingName, getPricings, toPricingGridPricingItem } from '../utilities/PricingUtilities';
import { LumpSumDataGridFields, UpcDataGridFields, ValidationScopes, ValidationSource } from '../models/enums';
import constants from '../models/consts';

export const MAX_AMOUNT = 999999.99;

const validateVendorContractNumber = async (
  customerId: string,
  contractId?: number,
  vendorContractNumber?: string
): Promise<ValidationIssue | undefined> => {
  const fieldName = 'VendorContractNumber';

  if (!vendorContractNumber)
    return ValidationIssue.Required({ scope: ValidationScopes.ContractHeader, field: fieldName, source: ValidationSource.Ui });

  const response = await contractTermService.vendorContractNumberIsValid({ customerId, contractId, vendorContractNumber });
  if (!response)
    return new ValidationIssue({
      scope: ValidationScopes.ContractHeader,
      field: fieldName,
      message: 'The contract # must be a unique value.',
      source: ValidationSource.Ui,
    });
};

const validateCustomer = async (customerId?: string): Promise<ValidationIssue | undefined> => {
  const fieldName = 'CustomerId';
  const displayField = 'Account #';

  if (!customerId)
    return ValidationIssue.Required({
      scope: ValidationScopes.ContractHeader,
      field: fieldName,
      displayField: displayField,
      source: ValidationSource.Ui,
    });

  const customer = await customerService.selectById(customerId);

  if (customer instanceof HttpErrorResponse || !customer?.isActive)
    return ValidationIssue.NotFound({
      scope: ValidationScopes.ContractHeader,
      field: fieldName,
      displayField: displayField,
      source: ValidationSource.Ui,
    });
};

const validateManufacturer = async (manufacturerId?: string): Promise<ValidationIssue | undefined> => {
  const fieldName = 'ManufacturerId';
  const displayField = 'Manufacturer';

  if (!manufacturerId)
    return ValidationIssue.Required({
      scope: ValidationScopes.ContractHeader,
      field: fieldName,
      displayField: displayField,
      source: ValidationSource.Ui,
    });

  const manufacturer = await manufacturerService.selectManufacturerById(manufacturerId);

  if (manufacturer instanceof HttpErrorResponse || !manufacturer?.isActive)
    return ValidationIssue.NotFound({
      scope: ValidationScopes.ContractHeader,
      field: fieldName,
      displayField: displayField,
      source: ValidationSource.Ui,
    });
};

const validateSupplier = async (supplierId?: number): Promise<ValidationIssue | undefined> => {
  const fieldName = 'SupplierId';
  const displayField = 'Supplier';

  if (!supplierId)
    return ValidationIssue.Required({
      scope: ValidationScopes.ContractHeader,
      field: fieldName,
      displayField: displayField,
      source: ValidationSource.Ui,
    });

  const supplier = await supplierService.selectById(supplierId);

  if (supplier instanceof HttpErrorResponse)
    return ValidationIssue.NotFound({
      scope: ValidationScopes.ContractHeader,
      field: fieldName,
      displayField: displayField,
      source: ValidationSource.Ui,
    });
};

const validateCategoryManager = async (categoryManagers: ICategoryManager[], categoryManagerId?: string): Promise<ValidationIssue | undefined> => {
  const scopeName = ValidationScopes.ContractHeader;
  const fieldName = 'CategoryManagerId';
  const displayField = 'Category Manager';

  if (!categoryManagerId)
    return ValidationIssue.Required({ scope: scopeName, field: fieldName, displayField: displayField, source: ValidationSource.Ui });

  const categoryManager = categoryManagers.find((q) => q.id === categoryManagerId && q.isActive);
  if (!categoryManager)
    return ValidationIssue.NotFound({ scope: scopeName, field: fieldName, displayField: displayField, source: ValidationSource.Ui });
};

const validateContractTermsForLumpSum = (
  lumpSums: IContractTermForLumpSum[],
  departments: IDepartment[],
  termTypes: ITermType[]
): ValidationIssue[] => {
  const scopeName = ValidationScopes.ContractTermsForLumpSum;

  const errors: ValidationIssue[] = [];

  for (const ls of lumpSums) {
    if (!ls.departmentId)
      errors.push(
        new ValidationIssue({
          scope: scopeName,
          message: `Department ID is required for ${constants.terms.displayNamePlural}.`,
          field: LumpSumDataGridFields.DepartmentId,
          identifier: ls.uniqueId,
          source: ValidationSource.Ui,
        })
      );
    else {
      const department = departments.find((q) => q.id === ls.departmentId);
      if (!department?.isActive)
        errors.push(
          ValidationIssue.NotFound({
            scope: scopeName,
            field: LumpSumDataGridFields.DepartmentId,
            identifier: ls.uniqueId,
            displayField: 'Department',
            source: ValidationSource.Ui,
          })
        );
    }

    if (!ls.effectiveDate)
      errors.push(
        new ValidationIssue({
          message: `Effective Date is required for ${constants.terms.displayNamePlural}.`,
          scope: scopeName,
          field: LumpSumDataGridFields.EffectiveDate,
          identifier: ls.uniqueId,
          source: ValidationSource.Ui,
        })
      );

    const termType = termTypes.find((tt) => tt.termTypeId === ls.termTypeId);

    if (!termType)
      errors.push(
        new ValidationIssue({
          message: `Type is required for ${constants.terms.displayNamePlural}.`,
          scope: scopeName,
          field: LumpSumDataGridFields.TermTypeId,
          identifier: ls.uniqueId,
          source: ValidationSource.Ui,
        })
      );
    else {
      if (!termType.isAd && ls.amount <= 0)
        errors.push(
          ValidationIssue.GreaterThan({
            input: 'Amount',
            comparison: '0.00',
            scope: scopeName,
            field: LumpSumDataGridFields.Amount,
            identifier: ls.uniqueId,
            source: ValidationSource.Ui,
          })
        );
      if (ls.amount > MAX_AMOUNT)
        errors.push(
          ValidationIssue.LessThanOrEqual({
            input: 'Amount',
            comparison: NumberToCurrencyString(MAX_AMOUNT),
            scope: scopeName,
            field: LumpSumDataGridFields.Amount,
            identifier: ls.uniqueId,
            source: ValidationSource.Ui,
          })
        );
    }
  }

  return errors;
};

const validateContractTermsForItem = (itemTerms: IContractTermForItem[]): ValidationIssue[] => {
  const scopeName = ValidationScopes.ContractTermsForItem;

  const errors: ValidationIssue[] = [];

  for (const itemTerm of itemTerms) {
    if (!itemTerm.startDate)
      errors.push(
        ValidationIssue.Required({
          scope: scopeName,
          field: 'StartDate',
          identifier: itemTerm.uniqueId,
          displayField: 'Start Date',
          source: ValidationSource.Ui,
        })
      );
    if (!itemTerm.endDate)
      errors.push(
        ValidationIssue.Required({
          scope: scopeName,
          field: 'EndDate',
          identifier: itemTerm.uniqueId,
          displayField: 'End Date',
          source: ValidationSource.Ui,
        })
      );
    if (!itemTerm.termTypeId)
      errors.push(
        ValidationIssue.Required({
          scope: scopeName,
          field: 'TermTypeId',
          identifier: itemTerm.uniqueId,
          displayField: `${constants.terms.displayName} Type`,
          source: ValidationSource.Ui,
        })
      );
    if (itemTerm.startDate && itemTerm.endDate && itemTerm.startDate > itemTerm.endDate) {
      errors.push(
        ValidationIssue.GreaterThan({
          input: 'EndDate',
          comparison: 'StartDate',
          scope: scopeName,
          field: 'StartDate',
          identifier: itemTerm.uniqueId,
          source: ValidationSource.Ui,
        })
      );
    }
  }

  return errors;
};

const validateContractItemAmounts = (
  ci: IContractItem,
  contractTermsForItems: IContractTermForItem[],
  scopeName: string,
  referenceData?: IReferenceDataState
): ValidationIssue[] => {
  const errors: ValidationIssue[] = [];
  //Since there can be multiple billback promos we must look through all the amounts.
  for (let i = 0; i < ci.amounts.length; i++) {
    const matchingItemTerm = contractTermsForItems[i];
    const field = UpcDataGridFields.ItemTerm(matchingItemTerm?.termTypeId ?? 0, i);

    const error_msg = ValidationIssue.Required({
      scope: scopeName,
      field: field,
      identifier: ci.sku,
      displayField: 'Amount',
      source: ValidationSource.Ui,
    });

    if (!ci.amounts || ci.amounts.length === 0) errors.push(error_msg);

    if (ci.amounts) {
      if (ci.amounts.length != contractTermsForItems.length) errors.push(error_msg);

      if (!matchingItemTerm) continue;

      const amount = ci.amounts[i];
      //Amounts for non matching random weights in a disabled cell have to be zero.
      if (
        referenceData &&
        ci.item && //if item is unknown, defer random weight check
        referenceData.termTypeUnitsOfMeasure.byId[matchingItemTerm.termUnitOfMeasureId].forRandomWeight !== ci.item.isRandomWeight &&
        amount !== 0
      ) {
        errors.push(error_msg);
      }

      // == and not === since it could be undefined or null
      if (amount == undefined) {
        errors.push(
          ValidationIssue.Required({
            scope: scopeName,
            field: field,
            identifier: ci.sku,
            displayField: 'Amount',
            source: ValidationSource.Ui,
          })
        );
      } else if (amount > MAX_AMOUNT) {
        errors.push(
          ValidationIssue.LessThanOrEqual({
            input: 'Amount',
            comparison: NumberToCurrencyString(MAX_AMOUNT),
            scope: scopeName,
            field: field,
            identifier: ci.sku,
            source: ValidationSource.Ui,
          })
        );
      }
    }
  }

  return errors;
};

const validateContractItemCaseListCost = (
  ci: IContractItem,
  contractTermsForItems: IContractTermForItem[],
  contractTermsForLumpSum: IContractTermForLumpSum[],
  scopeName: string
): ValidationIssue[] => {
  const errors: ValidationIssue[] = [];

  if (contractTermsForItems.length > 0 || contractTermsForLumpSum.length > 0) {
    if (ci.caseListCost == undefined || ci.caseListCost == null || ci.caseListCost.toString() == '')
      errors.push(
        ValidationIssue.Required({
          scope: scopeName,
          field: UpcDataGridFields.CaseListCost,
          identifier: ci.sku,
          displayField: 'Case List Cost',
          source: ValidationSource.Ui,
        })
      );
    else if (ci.caseListCost > MAX_AMOUNT)
      errors.push(
        ValidationIssue.LessThanOrEqual({
          input: 'CaseListCost',
          comparison: NumberToCurrencyString(MAX_AMOUNT),
          scope: scopeName,
          field: UpcDataGridFields.CaseListCost,
          identifier: ci.sku,
          source: ValidationSource.Ui,
        })
      );
  }
  return errors;
};

const validateContractItemSuggestedRetailPrice = (ci: IContractItem, scopeName: string): ValidationIssue[] => {
  const errors: ValidationIssue[] = [];

  if (ci.suggestedRetailPrice !== undefined && ci.suggestedRetailMultiple !== null && ci.suggestedRetailPrice > MAX_AMOUNT)
    errors.push(
      ValidationIssue.LessThanOrEqual({
        input: 'Suggested Retail Price',
        comparison: NumberToCurrencyString(MAX_AMOUNT),
        scope: scopeName,
        field: UpcDataGridFields.SuggestedRetailPrice,
        identifier: ci.sku,
        source: ValidationSource.Ui,
      })
    );
  else if (ci.suggestedRetailMultiple !== undefined && ci.suggestedRetailMultiple !== null && ci.suggestedRetailMultiple <= 0)
    errors.push(
      ValidationIssue.GreaterThan({
        input: 'Suggested Retail Multiple',
        comparison: '0',
        scope: scopeName,
        field: UpcDataGridFields.SuggestedRetailMultiple,
        identifier: ci.sku,
        source: ValidationSource.Ui,
      })
    );

  return errors;
};

const validateContractItem = (
  contractItem: IContractItem,
  contractTermsForItems: IContractTermForItem[],
  contractTermsForLumpSum: IContractTermForLumpSum[],
  parentFieldName?: string,
  referenceData?: IReferenceDataState
): ValidationIssue[] => {
  const scopeName = ValidationScopes.ContractItem;
  const errors: ValidationIssue[] = [];

  if (!contractItem) {
    errors.push(ValidationIssue.Required({ scope: scopeName, field: scopeName, displayField: 'Contract item', source: ValidationSource.Ui }));
    return errors;
  }

  if (contractItem.sku <= 1)
    errors.push(
      ValidationIssue.GreaterThan({ scope: scopeName, input: 'SKU', comparison: '1', identifier: contractItem.uniqueId, source: ValidationSource.Ui })
    );

  if (contractTermsForItems.length > 0) {
    errors.push(...validateContractItemAmounts(contractItem, contractTermsForItems, scopeName, referenceData));
    errors.push(...validateItemForTerms(contractItem, contractTermsForItems, contractItem.amounts));
  }

  errors.push(...validateContractItemCaseListCost(contractItem, contractTermsForItems, contractTermsForLumpSum, scopeName));
  errors.push(...validateContractItemSuggestedRetailPrice(contractItem, scopeName));

  return errors;
};

const validateItemForTerms = (
  contractItem: IContractItem,
  contractTermsForItems: IContractTermForItem[],
  amounts: (number | undefined)[]
): ValidationIssue[] => {
  const errors: ValidationIssue[] = [];
  const item = contractItem.item;
  if (!item) return [];

  if (item?.discontinuedDate) {
    for (let i = 0; i < contractTermsForItems.length; i++) {
      const term = contractTermsForItems[i];
      if (amounts.length > i) {
        const amount = amounts[i];
        if (amount && amount > 0 && item.discontinuedDate && term.startDate && term.startDate >= item.discontinuedDate)
          errors.push(
            new ValidationIssue({
              message: `item ${item.upc} is discontinued on ${item.discontinuedDate.toDateString()} before the promotional period.`,
              scope: ValidationScopes.ContractTermsForItem,
              field: UpcDataGridFields.ItemUpc,
              identifier: contractItem.uniqueId,
              source: ValidationSource.Ui,
            })
          );
      }
    }
  }

  return errors;
};

const validateContractItems = (
  contractItems: IContractItem[],
  contractTermsForItem: IContractTermForItem[],
  contractTermsForLumpSum: IContractTermForLumpSum[],
  referenceData?: IReferenceDataState
): ValidationIssue[] => {
  const fieldName = ValidationScopes.ContractItems;

  const errors: ValidationIssue[] = [];

  for (const ci of contractItems) {
    errors.push(...validateContractItem(ci, contractTermsForItem, contractTermsForLumpSum, fieldName, referenceData));
  }

  return errors;
};

const validateItemCounts = (
  contractTermsForItem: IContractTermForItem[],
  contractTermsForLumpSum: IContractTermForLumpSum[],
  contractItems: IContractItem[]
): ValidationIssue[] => {
  const errors: ValidationIssue[] = [];

  const scopeName = ValidationScopes.ContractItems;
  if (contractTermsForItem.length === 0 && contractTermsForLumpSum.length === 0)
    errors.push(
      new ValidationIssue({
        scope: scopeName,
        message: `At least one ${constants.terms.displayName.toLowerCase()} is required.`,
        source: ValidationSource.Ui,
      })
    );

  if (contractTermsForItem.length > 0 && contractItems.length === 0)
    errors.push(new ValidationIssue({ scope: scopeName, message: `At least one item is required.`, source: ValidationSource.Ui }));

  return errors;
};

const validateGrossMargins = (contract: IContract, contractItems: IContractItem[], referenceData?: IReferenceDataState): ValidationIssue[] => {
  const errors: ValidationIssue[] = [];

  if (!contract.pricings) return errors;
  const priceConfigs = getPricings(contract, false, referenceData);
  for (const pricing of contract.pricings) {
    const pricingName = getPricingName(pricing);
    const priceConfig = priceConfigs.find((q) => q.name === pricingName);

    for (const item of pricing.items) {
      if (!item.price) continue;
      const contractItem = contractItems.find((q) => q.sku === item.sku);
      if (!contractItem || !contractItem.item) continue;
      const pricingItem = toPricingGridPricingItem(contract, contractItem);
      const unitCost = unitCostCalculate(pricingItem, priceConfig);
      if (unitCost) {
        const grossMargin = grossMarginCalculate(unitCost, item.price);

        if (grossMargin !== undefined && (isNaN(grossMargin) || parseFloat(grossMargin.toFixed(1)) >= 100))
          errors.push(
            new ValidationIssue({
              scope: ValidationScopes.Pricings,
              message: `${contractItem.item?.upc ?? contractItem.sku}: GM% cannot be greater than or equal to 100.`,
              source: ValidationSource.Ui,
            })
          );
      }
    }
  }

  return errors;
};

const gatherErrors = async (...args: any[]): Promise<ValidationIssue[] | undefined> => {
  const results = await Promise.all(args);
  const errors = results.filter((q) => q).flatMap((q) => q);

  return errors.length > 0 ? errors : undefined;
};

const validateContract = async (
  contract: IContract,
  contractTerms: IContractTerms,
  referenceData?: IReferenceDataState
): Promise<ValidationIssue[] | undefined> => {
  if (!referenceData)
    return [new ValidationIssue({ scope: ValidationScopes.Global, message: `referenceData not populated.`, source: ValidationSource.Ui })];

  const categoryManagers = referenceData.categoryManagers.all;
  const departments = referenceData.departments.all;
  const termTypes = referenceData.termTypes.all;

  const errors = await gatherErrors(
    validateVendorContractNumber(contractTerms.customer?.customerId ?? '', contract.contractId, contractTerms.vendorContractNumber),
    validateCustomer(contractTerms.customer?.customerId),
    validateManufacturer(contractTerms.manufacturer?.manufacturerId),
    validateSupplier(contractTerms.supplier?.id),
    validateCategoryManager(categoryManagers, contractTerms.categoryManager?.id),
    validateContractTermsForLumpSum(contractTerms.contractTermsForLumpSum, departments, termTypes),
    validateContractTermsForItem(contractTerms.contractTermsForItem),
    validateGrossMargins(contract, contractTerms.contractItems, referenceData),
    validateContractItems(contractTerms.contractItems, contractTerms.contractTermsForItem, contractTerms.contractTermsForLumpSum, referenceData),
    validateItemCounts(contractTerms.contractTermsForItem, contractTerms.contractTermsForLumpSum, contractTerms.contractItems)
  );

  return errors;
};

export const validationService = {
  validateContract,
};
