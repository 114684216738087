import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { ICustomer, IManufacturer, ISupplier, ICategoryManager } from '../../../../../../models';

export const useFormValidation = () => {
  const schema = Joi.object({
    terms: {
      vendorContractNumber: Joi.string().required().messages({ 'string.empty': 'Vendor Contract # is required.' }),
      customer: Joi.object<ICustomer>().required().messages({ 'any.required': 'Customer is required.' }),
      manufacturer: Joi.object<IManufacturer>().required().messages({ 'any.required': 'Manufacturer is required.' }),
      supplier: Joi.object<ISupplier>().required().messages({ 'any.required': 'Supplier is required.' }),
      categoryManager: Joi.object<ICategoryManager>().required().messages({ 'any.required': 'Category Manager is required.' }),
    },
  });

  const joiValidation = joiResolver(schema, { abortEarly: false, allowUnknown: true }, { mode: 'async' });

  return {
    joiValidation,
  };
};
